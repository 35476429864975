import React, { Component } from 'react';
import Detail from '../Event/EventDetail';
import { Redirect } from 'react-router';

export class Card extends Component {

  constructor(props) {
    super(props);
    this.handlescanCameraClick=this.handlescanCameraClick.bind(this);
    this.state = {
        hits: [],
        isLoading: false,
        isloadingCamera:false,
        isloadingdetailsevent:false,
        EventID:0,
      };
  }

  handleDetailEventsClick(id, e){

    this.setState({isloadingdetailsevent: true});
     this.setState({EventID: id});
     let ids = [...this.state.hits];  
     let index = ids.findIndex(el =>el.id===id);

     let desc1=ids[index].description;
     let start1=ids[index].start;
     let name1=ids[index].name;
     let address1=ids[index].address;
     let summary1=ids[index].summary;
     let image1 = ids[index].image;
     let total1 = ids[index].total; 

     this.setState({name: name1});
     this.setState({start: start1});
     this.setState({desc: desc1});
     this.setState({address: address1});
     this.setState({summary: summary1});
     this.setState({image: image1 });
     this.setState({total: total1});
    
 }

 handlescanCameraClick()
 {
   this.setState({isloadingCamera: true});
 }

  componentDidMount() {
    fetch("https://www.mocky.io/v2/5c931894320000e51c6bd1c0")
      .then(response => response.json())
      .then(data => this.setState({ hits: data, isLoading: false }));
  }


  render() {
    const { hits,image, isLoading,isloadingdetailsevent,EventID,name,start,desc,address,isloadingCamera,summary, total, checkin} = this.state;
    var divStyle = {           
        margin: "0 auto",
        width:"90%",
        marginTop:"5px"
      };

      if (isLoading) {
        return <p>Loading ...</p>;
      }
      
      else if(isloadingdetailsevent)
      {
        return (
           <Detail EventID={EventID} image ={image} name={name} start={start} total ={total}  checkin={checkin} desc={desc} address={address}  summary={summary}></Detail>
        );
      }
      else if(isloadingCamera)
          {
               return ( 
                 
               <Redirect to='/admin/camera'/> 
              
               );
          }
      else
      {
       return(
<div className ="p-3">
        
  {hits.map((item)=>
    <div className="card border border-primary"  style={{marginTop:'20px'}} >
          
              <div className="d-flex flex-row ml-2 mt-2 mb-0" onClick={this.handleDetailEventsClick.bind(this,item.id)}>
                <i className="p-2 fas fa-fw fa-stopwatch" style={{color:'#199CD9',fontSize:'18px'}} ></i> 
                <h5 className="p-2 text-primary"> Happening now </h5>
              </div> 
              
              {item.image ? <img className="card-img-top" style={divStyle} src= {item.image}  alt="Card" onClick={this.handleDetailEventsClick.bind(this,item.id)}/> : <div></div>}

            <div className="card-body">
              <h5 className="card-title text-dark font-weight-bold"  onClick={this.handleDetailEventsClick.bind(this,item.id)}>{item.name}</h5>
              <p className="card-text text-dark" onClick={this.handleDetailEventsClick.bind(this,item.id)}>
                {item.start}
              </p>
              <p className="card-text" onClick={this.handleDetailEventsClick.bind(this,item.id)}>
                Campus location goes here  
              </p>
        <hr/>
          <div className="d-flex">  
                  <div className= "d-flex flex-column bd-highlight mb-3 flex-grow-1">  
                      <div className="font-weight-bold mr-auto">
                         {item.checkin}    2 / 31
                      </div>          
                      <div className="font-weight-bold mr-auto">
                        Checked in 
                      </div>
                  </div>  
                  <div className="p-2 flex-shrink-1 bd-highlight">
                    <button  onClick={this.handlescanCameraClick}  className="btn btn-primary" style= {{color:"white"}}><i className="fas fa-fw fa-ticket-alt"></i>{'   '}Scan ticket</button>
                  </div>        
                </div>    
        </div>
        </div>
       
  )}

</div>
         );
        }
  }

  
}

export default Card;
