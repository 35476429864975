import React from 'react';
import ReactDOM from 'react-dom'; 
class CheckedIn extends React.Component {

    constructor(props){
        super(props);
        
        this.state ={
         marking: false,
         hits: []
        }
    }


    componentDidMount() {
        fetch("http://www.mocky.io/v2/5c99b9813200007500d909b4")
          .then(response => response.json())
          .then(data => this.setState({ hits: data, isLoading: false }));
      }

    render() {
    const {hits} = this.state;
    return (

    <section className="p-3">

        <section className="action-panel fixed-top col-xl-5 col-12 pb-5">

           <div className="actions-panel-mobile card d-flex d-lg-none">
                            
                            <div className="sidebar-container flex-column flex-lg-row mt-3">
                                    <div className="card-body">
                                    <div className="input-group mr-2">
                                        <input type="text" className="form-control" placeholder="Search Events"/>
                                        <div className="input-group-append">
                                            <span className="input-group-text">
                                                <i className="fas fa-search" aria-label="Search"></i>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="">
                                            <div className= "d-flex flex-column flex-grow-1"  onClick={() => this.setState({isopen: true})}>  
                                              <div className="font-weight-bold mr-auto">
                                                   2 / 31
                                              </div>          
                                              <div className="font-weight-bold mr-auto">
                                                Checked in 
                                              </div>
                                          </div> 
                                   </div>

                                
                                </div>
                            </div>
             </div>
        </section>

            <div style= {{height: "30%"}}> 
            </div>
                     

            <div className="d-flex flex-row">
                    <h4 className="ml-2 text-primary">
                    Checked in students        
                    </h4>  
                </div>
                    <div className= 'ml-2'>
                   
                    {hits.map((item)=>

                        <div className="d-flex">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="" id={item.studentid} />
                            
                                <label className="form-check-label" for={item.studentid}>
                                    {item.firstname} {''} {item.lastname}
                                </label>
                                
                            <p className= "ml-4"> <span> student ID </span> {item.studentid} </p>
                        </div>
                       
                        </div>

                    )}                  
            </div>
            <div className="d-flex flex-row mt-3 ">
                    <h4 className="ml-2 text-dark font-weight-bold">
                    Confirmed Bookings     
                    </h4>  
                </div>
                    <div className= 'ml-2'>
                   
                    {hits.map((item)=>

                        <div className="d-flex">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="" id={item.studentid} />
                            
                                <label className="form-check-label" for={item.studentid}>
                                    {item.firstname} {''} {item.lastname}
                                </label>
                                
                            <p className= "ml-4"> <span> student ID </span> {item.studentid} </p>
                        </div>
                       
                        </div>

                    )}                  
            </div>
      </section>

            );
        }

}

ReactDOM.render(
    <CheckedIn/>,
    document.getElementById('root')
  );
    
export default CheckedIn;