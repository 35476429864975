import React from 'react';
import { Link } from 'react-router-dom';
import TestDrawer from'../Components/Admin/TestDrawer';
class Logincontrol extends React.Component {

    constructor() {
        super();
        this.handleLoginClick = this.handleLoginClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            isAdminLoggedIn: false,
            isStudentLoggedIn:false,
            email: '',
            password: '',
            fields: {}
        };
    }
    handleLoginClick(id, e) {
        
        /*let fields = this.state.fields;
        let uemail = fields["email"];
        let upass = fields["password"];
        //let flag = true;
        fetch('https://www.mocky.io/v2/5c92e4973200005d006bd11c')
            .then(function (response) {
                return response.json();
            })
            .then(function (myJson) {
               
                for (let i = 0; i < myJson.length; i++) {

                    if (uemail === myJson[i].firstName && upass === myJson[i].studentId)
                    {
                       
                        //flag = true;
                        break;
                    }
                   
                }
               
            });*/

           
            if(id==='a')
                {

                    this.setState({ isAdminLoggedIn: true });
                }
                else if(id==='s')
                {
                    this.setState({ isStudentLoggedIn: true });
                }
    }

    handleChange(e) {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields
        });

    }

    render() {

        const {isAdminLoggedIn,isStudentLoggedIn} = this.state;
   
        if (isAdminLoggedIn) {
            return <TestDrawer/>
            
            {/*<Link class="nav-link" to="/admin/login"></Link>; */}
                                                                      
        }
        else if(isStudentLoggedIn)
        {
            return <Link class="nav-link" to="/students/login"></Link>;
          
        }
        else
        {

            return (

        <div className="container p-5 h-100 d-flex">
                    <div className="row mx-auto my-auto pb-5">
                            <div className="col col-12 p-0">
                                <div className="card ch-shadow">
                                    <div id="login-logo" className="bg-primary p-4 d-flex align-items-center w-100">
                                        <div className="pr-3">
                                            <h1 className="text-white mb-0" style={{ fontSize: "18px"}}>
                                                CareerHub Admin
                                            </h1>
                                            <span className="text-white" style={{ fontWeight : "300" }}>University of Australia</span>
                                        </div>
                                        <div className="flex-grow-1 text-right">
                                            <img style={{height: "50px"}} src="https://chubby.careerhub.support/assets/img/careerhub-logo.svg" alt="CareerHub"/>
                                        </div>
                                    </div>
                    <div className="p-4">
                        <div id="login-form">
                            <div>
                                <div className="form-group">
                                    {/*<button class="btn btn-primary btn-block" onClick={this.handleLoginClick.bind(this,'s')}>
                                        
                                        Student Login
                                     </button>*/}
                                    <Link className="btn btn-primary btn-block" to="/students/login"> Student Login </Link>
                                </div>
                                <div className="form-group">
                                    {/*<button class="btn btn-primary btn-block" onClick={this.handleLoginClick.bind(this,'a')}>
                                    
                                        Admin Login
                                    </button>*/} 

                                    <Link className="btn btn-primary btn-block" to="/admin/login"> Admin Login </Link>
                                </div>
                                <hr/>
                                <div className="d-flex align-items-between">
                                        <div className="mr-5">
                                            <a href="http://localhost:3000">
                                                <i className="fas fa-undo-alt" aria-hidden="true"></i>
                                                Reset your Password?
                                            </a>
                                        </div>
                                        <div>
                                            <a href="http://localhost:3000">
                                                <i className="fas fa-cog" aria-hidden="true"></i>
                                                Support Login
                                            </a>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
            );
        }

    }
    

}
export default Logincontrol;