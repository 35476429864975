import React, { Component } from 'react';
import QrReader from 'react-qr-scanner';
import { Redirect } from 'react-router';
import {Drawer} from '@careerhub/chubby-react';
import CheckedIn from './CheckedIn';
//import CheckedIn from './CheckedIn';

export class Camera extends Component {
	constructor(props)
	{
		super(props);
		this.handleScan = this.handleScan.bind(this);
    this.HandleRotate=this.HandleRotate.bind(this);
    this.handlecancel = this.handlecancel.bind(this);
		this.state={
			isfacemode:false,
      isgetdata:false,
      facingmode: 'rear',
      result:'No result',
      isclose: false,
      isopen:false,
      side:  'top' | 'left' | 'bottom' | 'right',
      displayState: 'none',
      show: false
			
		};
	}
	
	  HandleRotate()
      {
        this.setState(state => ({ isfacemode: !state.isfacemode }));
       
      }

      handlecancel() 
      {
        this.setState({ isclose : true})
      }

    handleScan(data){
      if(data)
      {
      this.setState({
        result: data,
        isgetdata:true,
      })        
    }
	  
      else
      {
        this.setState({
          result: 'No result',
          isgetdata:false,
        })        
      }
	  
      }
      handleError(err){
        console.error(err)
      }


     
	
	
  render() {
   
   const {isfacemode,isgetdata, isclose, isopen, side} =this.state;
   
        let mode;
        if(isfacemode)
        {
          mode="rear";
        }
        else
        {
          mode="front";
        }

        if(isclose)
        {
          return <Redirect to='/admin/events'/>;       
        }


        let s;
        if(isgetdata)
        {
          s=<div className="alert alert-success mb-3" role="alert">
          <div class="flex-shrink-1">
            <i class="fas fa-check"></i>
          </div>
          <div class="flex-grow-1 alert-body">
            <strong>{this.state.result}</strong>
             
          </div>
          <div class="flex-shrink-1">
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">
                  <i class="fas fa-times"></i>
                </span>
            </button>
          </div>
        </div>

        }
        else
        {
          s=null;
        }
        var overlays = {
          position: "absolute",
          left: "0px",
          bottom: "10px",
          margin: "1px",
          padding: "5px 5px",
          fontSize: "20px",
          backgroundColor: "Transparent",
         

        }

        var ColorOverlay = {
          width: "100%",
          height: "20px",
          position: "absolute"
        }

        var textoverlay = {
          backgroundImage: "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%,rgba(0,0,0,0) 100%)",
          background: "-moz-linear-gradient(top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%),-webkit-linear-gradient(top, rgba(0,0,0,0.7) 0%,rgba(0,0,0,0) 100%), linear-gradient(to bottom, rgba(0,0,0,0.7) 0%,rgba(0,0,0,0) 100%) ",
          width: "96%",
          height: "100px",
          position: "absolute",
          fontSize: "20px",
          backgroundColor: "Transparent",
        }

        
    return (
      
    <div className="col d-flex flex-column h-100">

          <div>
              <div className="p-2" style={textoverlay}>
              <div className="d-flex flex-row">
                <h3 className="font-weight-bold text-white w-100">XVII Annual AI Study Group Meetup</h3>
                <button className="float-right flex-shrink-1 bd-highlight" style= {{backgroundColor: "transparent", outline: "none", border: "none", height: "30px", width: "30px"}} onClick = {this.handlecancel}>
                  <i class="fas fa-times" style= {{color: "white", fontSize: "18px"}}></i>
                  </button>
              
              </div>
              
              <h5 className="text-white">8:00am - 9:30am</h5>
              </div>
          </div>
            <div className="bg-dark flex-grow-1">
                <QrReader
                  delay={this.state.delay}
                  facingMode={mode}
                  //style ={previewStyle}
                  onError={this.handleError}
                  onScan={this.handleScan}
                  />
            </div>
            <div>
                <div style={ColorOverlay}>
                <button type="button" onClick={this.HandleRotate} className="btn btn-primary" style={overlays} > <i className="fas fa-sync-alt" style ={{color : "white"}}></i>{'      '}</button>
              </div>
            </div>
       
            <div>
            {/*
                <div className="alert alert-success mb-3" role="alert" style = {{marginBottom : "1.2rem"}}>
                <div className="flex-shrink-1">
                  <i className="fas fa-check"></i>
                </div>
                <div className="flex-grow-1 alert-body">
                  <strong>{this.state.result}</strong>
                  
                </div>
                <div className="flex-shrink-1">
                </div>
            </div>*/}
            </div>
            {s}
      
       <section className="action-panel col-xl-5 col-12" v-cloak v-show="data.loaded">
                            <div className="actions-panel-collapse drawer-mobile drawer-bottom d-none d-lg-block">

                                 {isopen !== undefined && 
                                    <Drawer isOpen={isopen} side={side || 'bottom'} onClickOut={() => this.setState({isOpen: false})}>
                                            <CheckedIn/>
                                    </Drawer>
                                }

                            </div>
                            <div className="actions-panel-mobile card d-flex d-lg-none">
                                <div className="sidebar-container flex-column flex-lg-row">
                                    <div className="card-body">
                                        <div className="input-group mr-2 w-75">
                                            <input type="text" className="form-control" placeholder="Search Events"/>
                                            <div className="input-group-append">
                                                <span className="input-group-text">
                                                    <i className="fas fa-search" aria-label="Search"></i>
                                                </span>
                                            </div>
                                        </div>

                                        <div className="">
                                            <div className= "d-flex flex-column bd-highlight flex-grow-1"  onClick={() => this.setState({isopen: true})}>  
                                              <div className="font-weight-bold mr-auto">
                                                   2 / 31
                                              </div>          
                                              <div className="font-weight-bold mr-auto">
                                                Checked in 
                                              </div>
                                          </div> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

       </div> 

    )
  }
}

export default Camera
