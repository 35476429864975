import React, { Component } from 'react';
import './App.scss';
import Login from './Common/LoginControl';
import StudentLogin from './Components/Student/Login/StudentLogin';
import AdminLogin from './Components/Admin/Login/AdminLogin';
import AdminEvents from './Components/Admin/Event/Event';
import StudentEvents from './Components/Student/Event/Event';
//import StudentEventDetails from './Components/Student/Event/EventDetails/EventDetails';
import AdminCamera from './Components/Admin/Camera/Camera';
import { Route, BrowserRouter as Router } from 'react-router-dom'


class App extends Component {

  render() {

    return (
     
        <Router>
            <div  className = "row h-100">  
                  <Route exact path="/" component={Login}/>
                  <Route path= "/admin/login" component={AdminLogin}/>
                  <Route path= "/students/login" component={StudentLogin}/>
                  <Route path = "/admin/events" component = {AdminEvents} />
                  <Route path = "/students/events" component = {StudentEvents}/>
                  <Route path = "/admin/camera" component = {AdminCamera}/>
             
            </div>
        </Router>
 
    );
  }
}



export default App;
