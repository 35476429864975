import React from 'react';
import { Alert } from 'reactstrap'; 
class CancelledEvent extends React.Component {

    constructor(props) {
        super(props);
    
        this.state = {
          visible: true
        };
    
        this.onDismiss = this.onDismiss.bind(this);
      }

      onDismiss() {
        this.setState({ visible: false });
      }

    render() {

    return (

            <Alert color="danger" isOpen={this.state.visible} toggle={this.onDismiss}>
                <div class="flex-shrink-1">
                    <i class="fas fa-exclamation-triangle"></i>
                </div>
                <div class="flex-grow-1 alert-body">
                    <strong>Event Cancelled!</strong>
                </div>
            </Alert>

       
            );
        }

}
    
export default CancelledEvent;