import React from 'react';
import Navigation from '../Navigation/Navigation';
import Card from '../Card/Card';
class Event extends React.Component {

    render() {

    return (
        <div className = "w-100">
                <Navigation/>
                <Card/>
       </div>

            );
        }

}
    
export default Event;