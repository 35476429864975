import React from 'react';
import { Link } from 'react-router-dom';
export default class Navigation extends React.Component {


    render() {
          
        return (
            
            <nav className="navbar navbar-expand-lg navbar-dark bg-primary d-flex flex-shrink-0 raise-2 ch-shadow" v-cloak ="true" v-if="data.support && data.loaded">
            <a className="navbar-brand" href="http://events.ict1.careerhub.com.au">
                <img className="mr-3" alt="logo" src="https://chubby.careerhub.support/assets/img/careerhub-logo.svg" style={{ width:"20px",height:"20px"}}/>
                Today
            </a>
            
            <button className="navbar-toggler pr-0 collapsed cursor-pointer" type="button" data-toggle="collapse"
                data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                aria-label="Toggle navigation">
                <div className="icon-bar"></div>
                <div className="icon-bar"></div>
                <div className="icon-bar"></div>
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav ml-auto">
                    <li className="nav-item">
                        <Link className="nav-link" to="/students/events">Events </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/">Sign Out</Link>
                    </li>
                </ul>
            </div>
        </nav>
      
      
           );
    }
    

}