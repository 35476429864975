import React from 'react';
import ReactDOM from 'react-dom'; 
import { Tabs, TabContent, TabLink } from 'react-tabs-redux';
import EventBookings from './EventBookings';

class EventDetail extends React.Component
{
    constructor(props)
    {
        super(props);
       
        this.state={
            isLoading:true,
            id:props.EventID,   
            name:props.name,
            start:props.start,
            desc:props.desc,
            address:props.address,    
            summary:props.summary, 
            image:props.image,   
            event:{},
        };
    }

 

    render()
    {
        const {name,start,desc,address,summary,image } = this.state;
        var divStyle = {           
            margin: "0 auto",
            width:"100%",
            marginTop:"5px"
          };
        return(
            
            <Tabs>
            <div className="bg-white w-100 ch-shadow raise">
              <ul className="w-100 overflow-x-auto nav nav-secondary nav-scroll w-100 flex-nowrap flex-lg-wrap can-scroll-right" >
              <li className="scroll-arrow-left">
                  <div className="icon-container">
                    <i className="fas fa-caret-left" aria-label="Scroll left"></i>
                  </div>
                </li>
                <li className="nav-item">
                  <TabLink className= "nav-link" to="tab1" style ={{backgroundColor : "white", border:0, outline: "none"}}>Events info</TabLink>
                </li>
                <li className="nav-item" >
                  <TabLink className= "nav-link" to="tab2" style ={{backgroundColor : "white", border:0, outline: "none"}} > Bookings</TabLink>
                </li>
              </ul>
            </div>

             <div>
           <TabContent for="tab1"> 
                                
            <div className="sidebar-container p-3 flex-column flex-lg-row">
                    <div className="page row bg-white p-2">
                        <section className="col  ">
                        <div className="ml-0">

                        {image ?  <img className="card-img-top" style={divStyle} src={image} alt="Card"/> : <div></div>}
                            <h2 className="headings-and-paragraphs mt-3">{name}</h2>                    
                        </div>
                        <div className="d-flex flex-row mt-3 mb-0">
                            <i className="fas fa-clock" style={{fontSize:'15px'}}></i>
                            <h5 className="ml-3">
                            {start}          
                            </h5>
                        </div>
                        <div className="d-flex flex-row mt-2 mb-0">
                            <i className="fas fa-map-marker-alt" style={{fontSize:'15px'}}></i>
                            <h5 className="ml-3">
                            {address}          
                            </h5>
                        </div>
                        <dl>
                            <dt className="mt-3">Summary</dt>
                            <dd className="mt-2">{summary}</dd>
                            <dt className="mt-4">Description</dt>
                            <dd className ="mt-2">{desc}</dd>
                        </dl>
                        </section>
                    </div>
                </div>

                </TabContent>
                <TabContent for="tab2">
                     <EventBookings/>
                </TabContent>
              </div>
            </Tabs>


           
        );
    }


}
ReactDOM.render(
    <EventDetail />,
    document.getElementById('root')
  );

  export default EventDetail;