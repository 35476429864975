import React from 'react';
import {Drawer} from '@careerhub/chubby-react';
import {Button, ButtonGroup} from 'reactstrap';
class TestDrawer extends React.Component {


    constructor (props){
        super(props)
        this.state = {
            isopen:false,
            side:  'top' | 'left' | 'bottom' | 'right',
            displayState: 'none',
            show: false
        };
    }

    render() {

      const { isopen , side } = this.state;


    return (
       
        <div>
        <Button color="primary" onClick={() => this.setState({isopen: true})}>Open</Button>
        <br/>
        Drawer is {isopen ? 'open' : 'closed'} on the {side || 'top'}.
        {isopen !== undefined && 
            <Drawer isOpen={isopen} side={side || 'top'} onClickOut={() => this.setState({isOpen: false})}>
                <div className="p-5">
                    <h1>Here stuff is in a Drawer, Yay!</h1>
                    <Button color="danger" onClick={() => this.setState({isOpen: false})}>Close</Button>
                    <hr/>
                    <ButtonGroup>
                        <Button onClick={() => this.setState({side: 'top'})}>Top</Button>
                        <Button onClick={() => this.setState({side: 'left'})}>Left</Button>
                        <Button onClick={() => this.setState({side: 'bottom'})}>Bottom</Button>
                        <Button onClick={() => this.setState({side: 'right'})}>Right</Button>
                    </ButtonGroup>
                </div>
            </Drawer>
        }
    </div>

            );
        }

}
    
export default TestDrawer;