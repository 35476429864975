import React from 'react';
import ReactDOM from 'react-dom'; 
import BreadCrumb from '../../Navigation/BreadCrumb';
import {Drawer} from '@careerhub/chubby-react';
import {Button} from 'reactstrap';
import Ticket from '../../Ticket/Ticket';
import CancelledEvent from '../CancelledEvent';

class EventDetail extends React.Component
{
    constructor(props)
    {
        super(props);
       
        this.state={
            isLoading:true,
            id:props.EventID,
            name:props.name,
            start:props.start,
            desc:props.desc,
            address:props.address,    
            summary:props.summary,
            image: props.image,
            event:{},
            isopen:false,
            side:  'top' | 'left' | 'bottom' | 'right',
            displayState: 'none',
            show: false
        };
    }
    componentWillMount() {
        //const id= this.state.id;
      }

    render()
    {
        const { name,start,desc,address,summary, image, isopen, side } = this.state;
        var divStyle = {           
            margin: "0 auto",
            width:"100%",
            marginTop:"5px"
          };
        return(

    <div className="sidebar-container p-3 flex-column flex-lg-row">
                <div className="d-flex flex-column bd-highlight mb-3">
                        <BreadCrumb/>
                        <CancelledEvent/>
                        <div className="page row bg-white">
                            <section className="col">
                            <div className="ml-0">
                            {image ? <img className="card-img-top" style={divStyle} src={image} alt="Card"/> : <div> </div>}
                                <h2 className="headings-and-paragraphs mt-3">{name}</h2>                    
                            </div>
                            <div className="d-flex flex-row mt-3 mb-0">
                                <i className="fas fa-clock" style={{fontSize:'15px'}}></i>
                                <h5 className="ml-3">
                                {start}          
                                </h5>
                            </div>
                            <div className="d-flex flex-row mt-2 mb-0">
                                <i className="fas fa-map-marker-alt" style={{fontSize:'15px'}}></i>
                                <h5 className="ml-3">
                                {address}          
                                </h5>
                            </div>
                            <dl>
                                <dt className="mt-3">Summary</dt>
                                <dd className="mt-2">{summary}</dd>
                                <dt className="mt-4">Description</dt>
                                <dd className ="mt-2">{desc}</dd>
                            </dl>
                            </section>
                        </div>
                </div>



                 <section className="action-panel" v-cloak v-show="data.loaded">
                            <div className="actions-panel-collapse drawer-mobile drawer-bottom d-none d-lg-block">

                                 {isopen !== undefined && 
                                    <Drawer isOpen={isopen} side={side || 'bottom'} onClickOut={() => this.setState({isOpen: false})}>
                                        <div className="card drawer-body">
                                                <div className="card-body bg-light">
                                                
                                                    <div className="d-flex flex-row mb-0">
                                                        <i className="p-2 fas fa-fw fa-stopwatch" style={{color:'#199CD9',fontSize:'18px'}} ></i> 
                                                        <h5 className="p-2 text-primary">
                                                            Starts soon          
                                                        </h5>
                                                </div>   
                                                 <Ticket/>
                                                
                                                </div>
                                            </div>
                                    </Drawer>
                                }

                            </div>
                            <div className="actions-panel-mobile card d-flex d-lg-none">
                            
                                <div className="sidebar-container flex-column flex-lg-row mt-3">
                                    <div className="card-body">
                                        <div className="input-group mr-2">
                                            <input type="text" className="form-control" placeholder="Search Events"/>
                                            <div className="input-group-append">
                                                <span className="input-group-text">
                                                    <i className="fas fa-search" aria-label="Search"></i>
                                                </span>
                                            </div>
                                        </div>

                                      
                                            <Button color="primary" className="w-50" onClick={() => this.setState({isopen: true})}> View Ticket</Button>
                                    
                                    </div>
                                </div>
                            </div>
                        </section>

    
    </div>


        );
    }


}
ReactDOM.render(
    <EventDetail/>,
    document.getElementById('root')
  );

  export default EventDetail;