import React, { Component } from 'react';

export class QRCodeCancelled extends Component {
  render() {
    var QRcode = require('qrcode.react');
    var overlays ={
        width: "80%",
        position: "absolute",
        top: "8rem",
        right: "0rem",
        left: "0rem",
        zIndex: "9999"
    }
    return (

            <div className="mt-3 text-center">
                        <div className="mx-auto" style= {overlays}>
                              <div class="alert alert-danger" role="alert">
                                <div class="icon-alert icon-danger"></div>

                                <div className="d-flex flex-column bd-highlight mx-auto w-100">
                                            <i className="fas fa-exclamation-triangle" style={{fontSize:'2rem'}}></i>
                                            <h1 className ="text-white mt-3 mx-auto" style={{width:'80%'}}>
                                              Event cancelled
                                            </h1>
                                            <hr className= "mb-0" style ={{ margin: "1em 0"}}/>
                                            <p className= "m-0 mt-3 text-white font-weight-light" style={{fontSize : "1rem" }}> This event has been cancelled.</p>
                                            <p className= "m-0 text-white font-weight-light" style={{fontSize : "1rem" }}> Refer to University staff </p>

                                </div>

                              
                              </div>
                        </div>

                      <div className="mx-auto" style={{opacity: '0.1'}}>
                        <QRcode className= "mx-auto"   value="http://facebook.github.io/react/" style={{width:256, height:256}}/>
                      </div>
            </div> 
             

    )
  }
}

export default QRCodeCancelled
