import React, { Component } from 'react';
//import QRCode from './QRCode';
//import QRCodeValidated from './QRCodeValidated';
import QRCodeCancelled from './QRCodeCancelled';

export class Ticket extends Component {

    constructor (props){
        super(props);
        this.handleEvent = this.handleEvent.bind(this);
        this.state = {
            eventsClick  : false, 
          };       
    }

    handleEvent =() => {
    alert("this is clicejd");
    }
    
   
  render() {

    var leftCircle = {           
        position: "relative",
        left: "-3.43rem",
        marginTop: "-2.5rem",
        width: "3.12rem",
        height: "3.12rem",
        borderRadius: "80%",
        backgroundColor: "#f1f1f1"
      };
      var rightCircle = {           
        position: "fixed",
        right: "-1.37rem",
        marginTop: "-3.12rem",
        width: "3.12rem",
        height: "3.12rem",
        borderRadius: "80%",
        backgroundColor: "#f1f1f1"
      };
    return (

    <div class="card-body bg-white">
               {/*<QRCode/>*/}
               {/*<QRCodeValidated/>*/}
               <QRCodeCancelled/>
        <hr style={{ borderTop: '2px dashed', color: '#CCCCCC'}}/>
            <div style={leftCircle}> 
            </div>
            <div style={rightCircle}> 
            </div>
        <h5 className="p-2 text-primary">
            Event Info         
        </h5>
        <p onClick>
            <h2 className="headings-and-paragraphs mt-3" onClick = {this.handleEvent} >
            A big event name goes here
            </h2>                    
        </p>
        <div className="d-flex flex-row mt-3 mb-0">
            <i className="fas fa-clock" style={{fontSize:'20px'}}></i>
            <h5 className="ml-3">
            2018-12-31T21:53:10.700Z      
            </h5>
        </div>
        <div className="d-flex flex-row mt-2 mb-0">
            <i className="fas fa-map-marker-alt" style={{fontSize:'20px'}}></i>
            <h5 className="ml-3">
            Hand Points         
            </h5>
        </div>
    </div> 
    )
  }
}

export default Ticket
