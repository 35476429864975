import React from 'react';
//import ReactDOM from 'react-dom';
import { Redirect } from 'react-router';
import { Form, FormGroup, Label, Input, FormFeedback, FormText } from 'reactstrap';
import axios from 'axios';

class StudentLogin extends React.Component {

    constructor() {
        super();
        this.handleLoginClick = this.handleLoginClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            isLoggedIn: false,
            email: '',
            password: '',
            fields: {},
            persons: [],
            msg:"",
        };
       

    }

    handleLoginClick() {
        let fields = this.state.fields;
        let uemail = fields["username"];
        let upass = fields["password"];
        axios.get(`https://www.mocky.io/v2/5c996b873200007500d908c2`)
         .then(res => {
             const person = res.data;
             var flag=false;
             for (let i = 0; i < person.length; i++) {
                if (uemail === person[i].UserName && upass === person[i].UserPassword && person[i].UserRole === 'admin')
                {
                    flag = true;
                    break;
                }
            }
            if(flag===true)
            {
                this.setState({isLoggedIn:true});
            }
            else
            {
                this.setState({msg:"Invalid username and password"}); 
            }
         
      })

    }
    handleChange(e) {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields
        });

    }
    render() {

        const isLoggedIn = this.state.isLoggedIn;
        const msg=this.state.msg;
        let button;
        if (isLoggedIn) {
            return (

                <div>
                     return <Redirect to='/admin/events'/>;
                </div>
               
            );
        }
        else {


            return (

        <div class="container p-5 h-100 d-flex">
                    <div class="row mx-auto my-auto pb-5">
                            <div class="col col-12 p-0">
                                <div class="card ch-shadow">
                                    <div id="login-logo" class="bg-primary p-4 d-flex align-items-center w-100">
                                        <div class="pr-3">
                                            <h1 class="text-white mb-0" style={{ fontSize: "18px"}}>
                                                CareerHub Admin
                                            </h1>
                                            <span class="text-white" style={{ fontWeight : "300" }}>University of Australia</span>
                                        </div>
                                        <div class="flex-grow-1 text-right">
                                            <img style={{height: "50px"}} src="https://chubby.careerhub.support/assets/img/careerhub-logo.svg" alt="CareerHub"/>
                                        </div>
                                    </div>
                    <div class="p-4">


                     {/*<Form>
                            <FormGroup>
                                <Label for="exampleEmail">Input without validation</Label>
                                <Input />
                                <FormFeedback>You will not be able to see this</FormFeedback>
                                <FormText>Example help text that remains unchanged.</FormText>
                                </FormGroup>
                                <FormGroup>
                                <Label for="exampleEmail">Valid input</Label>
                                <Input valid />
                                <FormFeedback valid>Sweet! that name is available</FormFeedback>
                                <FormText>Example help text that remains unchanged.</FormText>
                                </FormGroup>
                                <FormGroup>
                                <Label for="examplePassword">Invalid input</Label>
                                <Input invalid />
                                <FormFeedback>Oh noes! that name is already taken</FormFeedback>
                                <FormText>Example help text that remains unchanged.</FormText>
                                </FormGroup>
                                <FormGroup>
                                <Label for="exampleEmail">Input without validation</Label>
                                <Input />
                                <FormFeedback tooltip>You will not be able to see this</FormFeedback>
                                <FormText>Example help text that remains unchanged.</FormText>
                                </FormGroup>
                                <FormGroup>
                                <Label for="exampleEmail">Valid input</Label>
                                <Input valid />
                                <FormFeedback valid tooltip>Sweet! that name is available</FormFeedback>
                                <FormText>Example help text that remains unchanged.</FormText>
                                </FormGroup>
                                <FormGroup>
                                <Label for="examplePassword">Invalid input</Label>
                                <Input invalid />
                                <FormFeedback tooltip>Oh noes! that name is already taken</FormFeedback>
                                <FormText>Example help text that remains unchanged.</FormText>
                                </FormGroup>
                     </Form>*/}

















                        <div id="login-form">
                            <div>
                            <div class="form-group">
                                    <label for="username">Your Username</label>
                                    <input class="form-control" type="text" id="username" value={this.state.username}  name="username" placeholder="Username" onChange={this.handleChange.bind(this)}
                                        autofocus="autofocus" autocomplete="off"/>

                                </div>
                                <div class="form-group mb-4">
                                    <label for="password">Password</label>
                                    <input class="form-control" type="password" id="password" name="password"  onChange={this.handleChange.bind(this)}
                                        placeholder="Password" autocomplete="off"/>

                                </div>
                                <div class="form-group">
                                    <div class="form-check">
                                        <input class="form-check-input" id="isPersistent" name="isPersistent" type="checkbox"
                                            value="true" checked/>
                                        <label class="form-check-label" for="isPersistent">
                                            Keep me logged in for today
                                        </label>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <button class="btn btn-primary btn-block" onClick={this.handleLoginClick}>
                                        Sign in
                                    </button>
                                </div>
                                <div class="text-primary">
                                    {msg}
                                </div> 
                                <hr/>
                                <div class="d-flex align-items-between">
                                        <div class="mr-5">
                                            <a href="http://localhost:3000/">
                                                <i class="fas fa-undo-alt" aria-hidden="true"></i>
                                                Reset your Password?
                                            </a>
                                        </div>
                                        <div>
                                            <a href="http://localhost:3000/">
                                                <i class="fas fa-cog" aria-hidden="true"></i>
                                                Support Login
                                            </a>
                                        </div>
                                    </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div>
            );
        }

    }
    

}
export default StudentLogin;