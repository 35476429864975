import React from 'react';

class EventBookings extends React.Component {

    constructor(props){
        super(props);
        
        this.state ={
         marking: false,
         hits: []
        }
    }

    componentDidMount() {
        fetch("https://www.mocky.io/v2/5c987d122f000076009f304c")
          .then(response => response.json())
          .then(data => this.setState({ hits: data, isLoading: false }));
      }

      onHandleAttendance =()=>{
         /* fetch('https://www.mocky.io/v2/5c987d122f000076009f304c', {
            method: 'POST',
            body: JSON.stringify({id: '5bdcdfa40f0a326f858feae0'})
          })
          .then(res => res.text()) // OR res.json()
          .then(res => console.log(res))*/
      }

    render() {
        const {hits} = this.state;
    return ( 

        <div className ="p-3">
            <div className="page row bg-white m-3">
            <section className="col pb-5">
                <div className="d-flex flex-row mt-3 mb-0">
                        <h3 className="ml-2">
                        Confirmed bookings        
                        </h3>
                            <div className="form-check ml-5">

                                <div className="dropdown">
                                    <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Marked
                                    </button>
                                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                    <a className="dropdown-item" href= "http://localhost:3000" >Attended</a>
                                    <a className="dropdown-item" href= "http://localhost:3000" onClick={this.onHandleAttendance()}>Not attended</a>
                                    </div>
                                </div>
                        </div>
                    </div>
                        <div className= 'ml-2'>
                            <div className="form-check mt-2 ">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck2" disabled/>
                                <label className="form-check-label">
                                    SELECT ALL
                                </label>
                            </div>
                            <hr/>

                         {hits.map((item)=>

                         <div className="d-flex">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id={item.studentid} />
                               
                                    <label className="form-check-label" for={item.studentid}>
                                        {item.firstname} {''} {item.lastname}
                                    </label>
                                    
                                <p className= "ml-4"> <span> student ID </span> {item.studentid} </p>
                            </div>
                            <div className ="ml-auto">
                            {item.isattended === 'true' ? <span className="badge badge-primary">CHECKED IN</span> : <div> </div> }
                               
                            </div>
                        </div>

                            )}   
                                
                        </div>

                        </section>

                        <section className="action-panel col-xl-5 col-12" v-cloak v-show="data.loaded">
                            <div className="actions-panel-collapse drawer-mobile drawer-bottom d-none d-lg-block">
                            
                            </div>
                            <div className="actions-panel-mobile card d-flex d-lg-none">
                            
                                <div className="sidebar-container flex-column flex-lg-row mt-3">
                                        <div className="card-body">
                                        <div className="input-group mr-2">
                                            <input type="text" className="form-control" placeholder="Search Events"/>
                                            <div className="input-group-append">
                                                <span className="input-group-text">
                                                    <i className="fas fa-search" aria-label="Search"></i>
                                                </span>
                                            </div>
                                        </div>

                                        <button className="btn btn-secondary collapsed text-white" data-toggle="drawer"
                                            data-target=".actions-panel-collapse" aria-controls="actions-panel-collapse"
                            aria-expanded="false" aria-label="Toggle navigation">Book Students</button>

                                    
                                    </div>
                                </div>
                            </div>
                        </section>

        </div> 

             
   
      
      </div>
            

            );
        }

}
    
export default EventBookings;