import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
class BreadCrumb extends React.Component {

    render() {

    return (

    

      <Breadcrumb>
      <i className="fas fa-angle-left" style={{fontSize:'1rem'}} ></i>  &nbsp; &nbsp;
        <BreadcrumbItem active>Back to list of events </BreadcrumbItem>
      </Breadcrumb>

            );
        }

}
    
export default BreadCrumb;